<template>
  <div class="question">
    <div class="item">客户在线申请退货时，请确保：</div>
    <div class="item">1. 商品外包装完整，无拆封，相关附（配）件齐全；</div>
    <div class="item">
      2. 商品表面无划痕、无磨（破）损、无磕碰、无使用、无拆卸等痕迹；
    </div>
    <div class="item">
      3.
      防伪标识、标签或其他防伪措施未刮开、撕损、修改及改动（若有），唯一性三包卡不能缺失或撕损（若有）；：
    </div>
    <div class="item">4. 如有赠品，也必须将赠品一并退回；</div>
    <div class="item item_blod">
      请务必将商品的内带附件、保修卡、说明书、发票、检测报告（针对需出示的商品）等随同商品一起退回。“外包装”是指：生产厂商原包装。
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    backClick() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
.question {
  background: #fff;
  font-size: 14px;
  font-weight: 400;
  color: rgba(34, 34, 34, 1);
  line-height: 22px;
  padding: 10px 15px 15px;
  border-top: 1px solid #eee;
}
.item {
  padding-top: 5px;
  /* text-indent: 25px; */
}
.item_blod {
  font-weight: 500;
}
</style>
