<!--
 * @Descripttion: 
 * @FilePath: /azg/src/views/question/detail/components/Security.vue
 * @Author: 张兴业
 * @Date: 2020-11-09 10:22:40
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-05-12 13:30:07
-->
<template>
  <div class="question">
    <div class="item">
      精品通承诺所售商品均为海外原装正品，并承诺提供“正品保障”服务。
    </div>
    <div class="item">
      在精品通购物时，若经过合法认定买家已购得的商品为假货或非原装正品，买家有权在交易成功后90天内发起针对平台的投诉，并申请“正品保障”赔付，赔付的金额以买家实际支付的商品价款的<span
        class="item_blod"
        >“退一赔九+买家支付的邮费”</span
      >为限。
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    backClick() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.question {
  background: #fff;
  font-size: 14px;
  font-weight: 400;
  color: rgba(34, 34, 34, 1);
  line-height: 22px;
  padding: 15px;
  border-top: 1px solid #eee;
}
.item {
  padding-top: 10px;
  text-indent: 25px;
}
.item_blod {
  font-weight: 500;
}
</style>
