<template>
  <div class="question">
    <div class="list">
      <div v-for="(item, index) in list" :key="index" class="list_item">
        <img :src="item.img" alt="" />
        <div>{{ item.title }}</div>
      </div>
      <div class="list_line" />
    </div>
    <div class="right_item step_top">
      <img
        src="@/assets/mine/inform/1.1@2x.png"
        class="step_img"
      />注册并登录网站
    </div>
    <div class="right_item">
      1.新用户注册：点击首页顶部“免费注册”进入注册页面后输入用户名、密码及手机号验证码，按照提示完成注册。
    </div>
    <div class="right_item">
      2.老用户登录：请在”登陆 “页面输入登录账号、密码进行登录。
    </div>
    <img src="@/assets/mine/inform/login@2x.png" alt="" class="login_img" />
    <div class="right_item step_top">
      <img src="@/assets/mine/inform/2.2@2x.png" class="step_img" />加入购物车
    </div>
    <div class="right_item">
      1.挑选商品后，您可以“立即购买”或者“加入购物车”；
    </div>
    <div class="right_item">
      2.在购物车中，系统默认每件商品的订购数量为1件，如果您想购买多件商品，可修改购买数量；
    </div>
    <div class="right_item">3.在购物车中，您可以将商品单个或批量删除</div>
    <div class="right_item step_top">
      <img src="@/assets/mine/inform/3.3@2x.png" class="step_img" />提交订单
    </div>
    <div class="right_item">
      1.浏览要购买的商品，选择购买数量点击“立即购买”，直接结算。
    </div>
    <div class="right_item">2.或者在购物车中，选择好商品及数量后“去结算”。</div>
    <div class="right_item">3.填写购物人信息，收货信息等。</div>
    <div class="right_item step_top">
      <img src="@/assets/mine/inform/4.4@2x.png" class="step_img" />支付货款
    </div>
    <div class="right_item">
      确认无误后点击“提交订单”，生成新订单并显示订单编号。跳转至支付选择页面进行支付。
    </div>
    <div class="right_item step_top">
      <img src="@/assets/mine/inform/5.5@2x.png" class="step_img" />查看订单状态
    </div>
    <div class="right_item">您可进入“我的订单”查看订单详细信息。</div>
    <div class="right_item step_top">
      <img src="@/assets/mine/inform/6.6@2x.png" class="step_img" />订单状态
    </div>
    <div class="right_item">在“我的订单”中您可以查看到您订单的状态。</div>
    <div class="right_item step_color">1. 等待付款</div>
    <div class="right_item">
      当您提交订单但未支付货款时，订单状态将显示为等待付款。
    </div>
    <div class="right_item step_color">2. 已发货</div>
    <div class="right_item">
      当您的订单已由库房发出，正由快递公司送货，“订单状态”将显示已发货。
    </div>
    <div class="right_item step_color">3. 交易成功</div>
    <div class="right_item">
      当您签收商品之后，也就是物流得到签收完成后，“订单状态”即显示交易成功。
    </div>
    <div class="right_item step_color">4. 等待发货</div>
    <div class="right_item">
      当您的订单已经完成配货，正在等待发货时，订单状态将显示“等待发货”。
    </div>
    <div class="right_item step_color">5. 订单关闭</div>
    <div class="right_item">当您的订单已被关闭，订单状态将显示“订单关闭”。</div>
    <div class="right_item step_color">6. 交易失败</div>
    <div class="right_item">
      当您支付后，商品未发货之前，如果您想取消订单，则后台客服可以将订单设为交易失败。
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [
        {
          title: "注册账号并登陆",
          img: require("@/assets/mine/inform/1@2x.png")
        },
        {
          title: "查找商品",
          img: require("@/assets/mine/inform/2@2x.png")
        },
        {
          title: "放入购物车",
          img: require("@/assets/mine/inform/3@2x.png")
        },
        {
          title: "提交订单",
          img: require("@/assets/mine/inform/4@2x.png")
        },
        {
          title: "支付货款",
          img: require("@/assets/mine/inform/5@2x.png")
        },
        {
          title: "查看订单状态",
          img: require("@/assets/mine/inform/6@2x.png")
        },
        {
          title: "收货",
          img: require("@/assets/mine/inform/7@2x.png")
        }
      ]
    };
  },
  methods: {
    backClick() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
.question {
  background: #fff;
  font-size: 14px;
  font-weight: 400;
  color: rgba(34, 34, 34, 1);
  line-height: 22px;
  padding: 15px 15px 40px;
  border-top: 1px solid #eee;
}
.item {
  padding-top: 10px;
  text-indent: 25px;
}
.item_blod {
  font-weight: 500;
}
.list {
  display: flex;
  position: relative;
  margin-bottom: 10px;
}
.list img {
  width: 26px;
  height: 26px;
}
.list_item {
  flex: 1;
  margin: 10px 12px;
  text-align: center;
  z-index: 1;
  color: #666;
  font-size: 14px;
  font-weight: 400;
}
.list_line {
  height: 2px;
  background: rgba(125, 213, 137, 1);
  position: absolute;
  top: 22px;
  left: 15px;
  right: 15px;
  z-index: 0;
}
.right_item {
  padding: 10px 0 0 22px;
  color: #666;
}
.step_top {
  padding: 20px 0 0;
  font-size: 14px;
  font-weight: 500;
  font-size: 18px;
  color: #222;
}
.step_img {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.step_color {
  font-size: 14px;
  font-weight: 500;
  color: #222;
}
.login_img {
  width: 100%;
  height: 100%;
}
</style>
