<template>
  <div class="question">
    <div class="item item_index">
      精品通--为中国消费者提供更多放心、优质、舒心的母婴产品、美妆个护、食品保健等类目。<span
        class="item_title"
        >关键词：澳新购、价值创造者、定义优质生活体验</span
      >
    </div>
    <div class="item item_index item_top">
      精品通希望通过这个平台让更多的中国消费者接触到澳新乃至整个全球最优秀的产品，足不出户即可购买到这个世界上任意一个国家的任意产品。
    </div>
    <div class="item item_index">
      同时，<span class="item_title"
        >>精品通做一个负责任、有担当的平台，有使命把更多的好品牌和产品推荐出去。</span
      >
    </div>
    <div class="item item_index">
      精品通是希望通过这个平台去创造一种价值：可以让所有使用精品通的用户都可以有更优质的生活，更放心、舒心的体验。
    </div>
    <div class="item item_index">
      精品通承诺其销售的所有商品是<span class="item_title"
        >“海外正品保障，海外直供”</span
      >，为您创造一个便捷的跨境购物体验，足不出户轻松扫遍澳新，让您省心更放心！
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    backClick() {
      this.$router.back();
    },
  },
};
</script>

<style scoped>
.question {
  background: #fff;
  font-size: 14px;
  font-weight: 400;
  color: rgba(34, 34, 34, 1);
  line-height: 22px;
  padding: 15px;
  border-top: 1px solid #eee;
}
.item {
  padding-top: 10px;
}
.item_index {
  text-indent: 25px;
}
.item_title {
  font-size: 14px;
  font-weight: 500;
  color: rgba(34, 34, 34, 1);
}
.item_top {
  margin-top: 30px;
}
.go {
  color: #2976e8;
}
</style>
