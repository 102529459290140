<!--
 * @Descripttion: 
 * @FilePath: /azg/src/views/question/detail/index.vue
 * @Author: 张兴业
 * @Date: 2020-12-21 13:32:21
 * @LastEditors: 张兴业
 * @LastEditTime: 2021-05-27 17:40:00
-->
<template>
  <div class="question text_left">
    <!-- <title-bar-back
      v-if="this.$route.query.whereFrom !== 'app'"
      :title="$route.query.name"
      @back="back"
    >
      <div class="title_right"></div>
    </title-bar-back> -->
    <!-- 海外直供 -->
    <over-seas v-if="$route.query.key == 1" />
    <!-- 正品保障 -->
    <security v-if="$route.query.key == 2" />
    <!-- 购买流程 -->
    <sales-process v-if="$route.query.key == 3" />
    <!-- 常见问题 -->
    <common-problem v-if="$route.query.key == 4" />
    <!-- 破损包赔 -->
    <break-down v-if="$route.query.key == 6" />
    <!-- 退货须知 -->
    <return-policy v-if="$route.query.key == 7" />
    <!-- 退款说明 -->
    <refund-instruction v-if="$route.query.key == 8" />
    <!-- 配送方式 -->
    <distribution-mode v-if="$route.query.key == 10" />
    <!-- 物流追踪 -->
    <logistics-tracking v-if="$route.query.key == 11" />
    <!-- 关于平台 -->
    <about-platform v-if="$route.query.key == 12" />
    <!-- 告消费者书 -->
    <notification v-if="$route.query.key == 13" />
    <!-- AZG门店收益&提现常见问题FAQ -->
    <ti-xian-question v-if="$route.query.key == 14" />
    <!-- 发票说明 -->
    <invioce v-if="$route.query.key == 15" />
  </div>
</template>

<script>
// const TitleBarBack = () =>
//   import("@/components/TitleBarBack").then(m => m.default || m);
import OverSeas from "./components/Overseas.vue";
import Security from "./components/Security.vue";
import SalesProcess from "./components/SalesProcess.vue";
import CommonProblem from "./components/CommonProblem.vue";
import BreakDown from "./components/BreakDown.vue";
import ReturnPolicy from "./components/ReturnPolicy.vue";
import RefundInstruction from "./components/RefundInstruction.vue";
import DistributionMode from "./components/DistributionMode.vue";
import LogisticsTracking from "./components/LogisticsTracking.vue";
import AboutPlatform from "./components/AboutPlatform.vue";
import Notification from "./components/Notification.vue";
import TiXianQuestion from "./components/TiXianQuestion";
import Invioce from "./components/Invioce.vue";

export default {
  name: "QuestionDetail",
  components: {
    // TitleBarBack,
    OverSeas,
    Security,
    SalesProcess,
    CommonProblem,
    BreakDown,
    ReturnPolicy,
    RefundInstruction,
    DistributionMode,
    LogisticsTracking,
    AboutPlatform,
    Notification,
    TiXianQuestion,
    Invioce
  },
  data() {
    return {
      list: {
        a: []
      }
    };
  },
  methods: {
    back() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
.question {
  /* margin-top: 40px; */
}
.title_right {
  width: 36px;
}
</style>
