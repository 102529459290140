<template>
  <div class="question">
    <div class="item">
      1. 关于平台在售商品开票
      <div>
        一般贸易的商品，可以开具纸质增值税普通发票（简称“纸质普通发票”）、增值税电子普通发票（简称“电子普通发票”）、以及增值税专用发票（简称“专用发票”）。
      </div>
      <div>
        跨境贸易的商品，无法开具国内的增值税发票。（购买跨境商品等同于在境外直接购买商品，商品的销售方为境外公司，在国外并没有增值税发票这个概念，所以无法开具）。
      </div>
    </div>
    <div class="item">
      2. 关于发票开具流程
      <div>
        一般贸易的商品可以在确认收货两个月内申请开具发票，请联系APP内人工客服。
      </div>
    </div>
    <div class="item">
      3. 关于发票补开
      <div>
        根据国税局相关要求，对于普通发票遗失，我们会向您提供发票第一联复印件并加盖发票章；对于增值税专用发票丢失的，我们会向税务局申请开具《增值税专用发票已抄报税证明单》并连同发票第一联复印件加盖发票章提供给您。请注意由此产生的运费由责任方承担。
      </div>
    </div>
    <div class="item">
      4. 关于发票开具内容
      <div>
        根据国家税务总局公告2017年第45号《国家税务总局关于增值税发票管理若干事项的公告》第一条：自2018年1月1日起，纳税人通过增值税发票管理新系统开具增值税发票（包括：增值税专用发票、增值税普通发票、增值税电子普通发票）时，商品和服务税收分类编码对应的简称会自动显示并打印在发票票面“货物或应税劳务、服务名称”或“项目”栏次中。包含简称的《商品和服务税收分类编码表》见附件。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    backClick() {
      this.$router.back();
    }
  }
};
</script>

<style scoped>
.question {
  background: #fff;
  font-size: 14px;
  font-weight: 400;
  color: rgba(34, 34, 34, 1);
  line-height: 22px;
  padding: 10px 15px 15px;
  border-top: 1px solid #eee;
}
.item {
  padding-top: 5px;
  /* text-indent: 25px; */
}
.item_blod {
  font-weight: 500;
}
</style>
